import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import "../CSS/createAcc.css"
import Header from "./Header.js"
import api from '../api'
import { useHistory } from 'react-router-dom';
import { useTranslation} from "react-i18next";
import { getTranslations } from '../getTranslations'
import { Form, Card, InputGroup, Modal, Button } from 'react-bootstrap';

const ResetPasswordWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info
  const [userFields, setUserFields] = useState([null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
        api.getUserBySession().then(res => {
          let tempFields = [...userFields]
          tempFields[0] = true;
          tempFields[1] = res.data.user.admin
          tempFields[2] = res.data.user.applicationSent
          tempFields[3] = res.data.user.qualified
          tempFields[4] = res.data.user.username
          tempFields[5] = res.data.user.loan_information
          setUserFields(tempFields)
  
    
        }).catch((error) => {
          let tempFields = [...userFields]
          tempFields[0] = false;
          setUserFields(tempFields)
        })
      }, [])
  
  return(
    <div>
    {
        gotTrans && userFields[0] !== null ?
      <ResetPassword userFields={userFields} token={props.match.params}/>
      :
      null
    }
    </div>
  )
  
  }
  


var yup = require('yup');
const ResetPassword = (props) => {
    const { t, i18n } = useTranslation();

    const history = useHistory();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }
    const schema = yup.object({
        password: yup
            .string()
            .required(t("createAcc.passwordFirst.required_E"))
            .matches(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/, t("createAcc.passwordFirst.matches_E"))
            .max(40, t("createAcc.passwordFirst.max_E")),

        passwordSecond: yup
            .string()
            .required(t("createAcc.passwordSecond.required_E"))
            .matches(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/, t("createAcc.passwordSecond.matches_E"))
            .oneOf([yup.ref('password'), null], t("createAcc.passwordSecond.oneOf_E"))
            .max(40, t("createAcc.passwordSecond.max_E")),

    });
    const { token } = props.token;

    const [showSuccess, setShowSuccess] = useState(false)
   let successModal = (
        <div>
          <Modal show={true}>
            <Modal.Header>
              <Modal.Title>Success!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Your password has been reset!</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={()=>{history.push("/login")}}>
                Login
              </Button>
            </Modal.Footer>
          </Modal>
        </div>)

    const handleResetPass = async (data, { setSubmitting }) => {
        setSubmitting(true)
        let payload = { "token": token }
        delete data.passwordSecond
        data.token = token;
        api.checkToken(payload).then((res) => {
            if (res.data.success) {
                api.resetPassword(data).then((res) => {
                    if (res.data.success) {
                        setShowSuccess(true)
                    }
                })
            }
        }).catch((err) => {
            alert("Error, Reset password again")
        })
    }




    const [isValid, setValid] = useState(false);
    const payload = { "token": token }

    useEffect(() => {
        api.checkToken(payload).then((res) => {
            if (res.data.success) {
                setValid(true)
            }

        }).catch((error) => {
            console.log(error)
            alert("error")
        })
    }, [])


    return (
        <>
            {isValid ?
                <div className="App">
                    {showSuccess ? successModal : null}
                    <Header setLanguage={setLanguage} username={props.userFields[4]} />
                    <Card className="loginCard" >
                        <Card.Body>
                            <Formik
                                validationSchema={schema}
                                onSubmit={handleResetPass}
                                initialValues={{
                                    password: '',
                                }}
                            >


                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    touched,
                                    isSubmitting,
                                    errors,
                                }, ) => (
                                        <Form onSubmit={handleSubmit}>
                                            <h3 className="centerLoginTitle">{t('resetPassword.title')}</h3>
                                            <br />


                                            <div className="form-group">
                                                <Form.Label>{t('resetPassword.label1')}</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-key prefix"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Field name="password" type="Password" placeholder="Enter Password"
                                                        className="form-control"
                                                        isInvalid={!!errors.password && touched.password} isValid={!!!errors.password && touched.password}
                                                        as={Form.Control} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                            </div>



                                            <div className="form-group">
                                                <Form.Label>{t('resetPassword.label2')}</Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-key prefix"></i></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control name="passwordSecond"
                                                        type="Password"
                                                        placeholder="Confirm Password"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={!!errors.passwordSecond && touched.passwordSecond}
                                                        isValid={!!!errors.passwordSecond && touched.passwordSecond} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.passwordSecond}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>

                                            </div>

                                            <br />

                                            <button type="submit" disabled={isSubmitting} className="btn btn-primary btn-block">{t('resetPassword.reset')}</button>
                                            <br />
                                        </Form>

                                    )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </div>
                :
                null}
        </>//
    )
}

export default ResetPasswordWrapper
