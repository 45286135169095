import React, { useState, useEffect } from 'react';
import "../CSS/Qualifications.css"
import Header from "./Header.js"
import { Formik, Field } from 'formik';
import api from '../api'
import { useHistory, } from 'react-router-dom';
import "../CSS/Login.css"
import { useTranslation } from "react-i18next";
import { Button, Form, Card, Modal, Alert, Col, Spinner } from 'react-bootstrap';
import { getTranslations } from '../getTranslations'

const LoginWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info
  const [userFields, setUserFields] = useState([null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
      api.getUserBySession().then(res => {
        let tempFields = [...userFields]
        tempFields[0] = true;
        tempFields[1] = res.data.user.admin
        tempFields[2] = res.data.user.applicationSent
        tempFields[3] = res.data.user.qualified
        tempFields[4] = res.data.user.username
        tempFields[5] = res.data.user.loan_information
        setUserFields(tempFields)

  
      }).catch((error) => {
        let tempFields = [...userFields]
        tempFields[0] = false;
        setUserFields(tempFields)
      })
    }, [])

return(
  <div>
  {
    gotTrans && userFields[0] !== null ?
    <Login userFields={userFields}/>
    :
    null
  }
  </div>
)

}



var yup = require('yup');

const ResetNotification = (props) => {

  const history = useHistory();
  const routeLogin = () => {
    window.location.reload();
    return false;
  }

  return (
    <div>
      <Modal show={true}>
        <Modal.Header>
          <Modal.Title>{props.t('forgotPassword.sent')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.t('forgotPassword.body')}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={routeLogin}>
            {props.t('forgotPassword.return')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const ForgotPassword = (props) => {

  const [showModal, setShowModal] = useState(false);
  const handleForgotPassword =  (name, { setSubmitting }) => {
    setSubmitting(true)
    api.createToken(name).then(res => {
      setShowModal(true)
    })
  }

  const schema = yup.object({
    email: yup
      .string()
      .required()
  });

  return (
    <div>
      {showModal ? <ResetNotification t={props.t} /> : null}
      <Formik
        validationSchema={schema}
        onSubmit={handleForgotPassword}
        initialValues={{
          email: '',
        }}
      >
        {({
          handleSubmit,
          isSubmitting
        }, ) => (
            <Form onSubmit={handleSubmit}>
            
              <button type="button" id="dropdownMenu1" className="btn btn-outline-secondary" 
              style={{whiteSpace:"nowrap"}}
              onClick={() => window.location.reload()}
              >
              Back
              </button>
              <h3 className="centerLoginTitle">{props.t("forgotPassword.title")}</h3>
              <br />
              <p>{props.t("forgotPassword.info")}</p>
              <div className="form-group">
                <Field placeholder={props.t("forgotPassword.enterEmail")} name="email" type="input" className="form-control" as={Form.Control} />
              </div>

              <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
              {isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : null}
              {props.t("forgotPassword.submit")}</button>
              <br />
            </Form>
          )}
      </Formik>
    </div>
  )
}

const Login = (props) => {

  const history = useHistory();
  if (props.userFields[0] && props.userFields[1])
    history.push('/admin')


  else if (props.userFields[0] && (props.userFields[3] || props.userFields[2]))
    history.push('/home')

  else if (props.userFields[0] && !props.userFields[2] && props.userFields[2] != null)
    history.push('/application')


  else if (props.userFields[0] === false && props.userFields[0] != null)
    return (<Loginz username={props.userFields[4]} reset={props.reset}/>)


  return (
    null
  )


}

const Loginz = (props) => {

  const { t, i18n } = useTranslation();

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }

  const schema = yup.object({
    username: yup
      .string()
      .required(),

    password: yup
      .string()
      .required()

  });

  const history = useHistory();
  //chooses if you want to render forgotpassword component
  const [forgotPass, setForgotPass] = useState(false);

  //[0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified
  const [userFields, setUserFields] = useState([null, null, null, null])

  const [errorCode, setErrorCode] = useState(0)

  const handleLogin =  (data, { setSubmitting }) => {
    setErrorCode(0)
    setSubmitting(true);
     api.Login(data).then( res => {
      let payload = { "wanted": "qualified admin applicationSent" }
        api.getUserBySession(payload).then(res => {
        let tempFields = [...userFields]
        tempFields[0] = true;
        tempFields[1] = res.data.user.admin
        tempFields[2] = res.data.user.applicationSent
        tempFields[3] = res.data.user.qualified
        setUserFields(tempFields)

      })
    }).catch((error) => {
      let tempFields = [...userFields]
      tempFields[0] = false;
      setUserFields(tempFields)
      setSubmitting(false)
      if (error.response != null && error.response.data.code === 1)
        setErrorCode(1)
      else
        setErrorCode(2)
        console.log(error.response)
    })
  }


  let errorAlert = (
        errorCode === 1 ?
          <Alert variant="danger">
            <Alert.Heading>{t('login.invalidCred')}</Alert.Heading>
            <p>{t('login.invalidCredBody')}</p>
          </Alert>
          :
          <Alert variant="danger">
            <Alert.Heading>{t('login.unkown')}</Alert.Heading>
            <p>{t('login.unkownBody')}</p>
          </Alert>
  )

  if (userFields[0] && userFields[1])
    history.push('/admin')


  else if (userFields[0] && (userFields[3] || userFields[2]))
  {
    history.push('/home')
  }

  else if (userFields[0] && !userFields[2] && userFields[2] != null)
    history.push('/application')


  return (
    <>
      <div>
        <Header setLanguage={setLanguage} username={props.username}/>
        <div className="row justify-content-center">
        <Card as={Col} md="4">
          <Card.Body>
          {errorCode != 0 ? errorAlert : null}
            {forgotPass === false ?
              
              <Formik
              validationSchema={schema}
              onSubmit={handleLogin}
              initialValues={{
              username: '',
              password: '',
            }}
            >


              {({
              handleSubmit,
              values,
              touched,
              isValid,
              isSubmitting,
              errors,
            }, ) => (
                <Form onSubmit={handleSubmit}>
                  <h3 className="centerLoginTitle">{t("login.title")}</h3>
                  <br />
                  <div className="form-group">
                    <label>{t("login.username")}</label>
                    <Field placeholder={t("login.usernamePlaceHolder")} name="username" type="input" className="form-control" as={Form.Control} />
                  </div>

                  <div className="form-group">
                    <label>{t("login.password")}</label>
                    <Field placeholder={t("login.passwordPlaceHolder")} name="password" type="password" className="form-control" as={Form.Control} />
                  </div>

                  <br />
                  <div className="form-group">

                      <button type="button" id="dropdownMenu1" className="btn btn-outline-secondary" 
                      style={{whiteSpace:"nowrap"}}
                      onClick={() => {setForgotPass(true); setErrorCode(0)}}
                      >
                      {t("login.forgotPass")}
                      </button>

                  </div>

                  <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/> : null}
                  {t("login.submit")}</button>
                  <br />
                </Form>

              )}
            </Formik>

              :

              <ForgotPassword t={t} />
            }
          </Card.Body>
        </Card>
        </div>
    </div>
    </>
  );

}

export default LoginWrapper;

