import React, { useState, useEffect } from 'react';
import "../CSS/Qualifications.css"
import Header from "./Header.js"
import "../CSS/AccountHome.css"
import { useHistory, } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { getTranslations } from '../getTranslations'
import api from '../api'

import { Card, Row, Col, Tabs, Tab, Button } from 'react-bootstrap';

const AccountHomeWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info, [6]=Additional Application Count
  const [userFields, setUserFields] = useState([null, null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
        api.getUserBySession().then(res => {
          let tempFields = [...userFields]

          tempFields[0] = true;
          tempFields[1] = res.data.user.admin
          tempFields[2] = res.data.user.applicationSent
          tempFields[3] = res.data.user.qualified
          tempFields[4] = res.data.user.username
          tempFields[5] = res.data.user.loan_information
          tempFields[6] = res.data.user.additionalApplications.length
          setUserFields(tempFields)

        }).catch((error) => {
          let tempFields = [...userFields]
          tempFields[0] = false;
          setUserFields(tempFields)
        })
      }, [])

  return(
    <div>
    {
        gotTrans && userFields[0] !== null ?
      <AccountHome userFields={userFields}/>
      :
      null
    }
    </div>
  )

}



const ProcessesingApp = (props) => {
    const { t, i18n } = useTranslation();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const history = useHistory();

    return (
        <div>
            <Header setLanguage={setLanguage} username={props.username} />
            <div className="row justify-content-center">
                <Card as={Col} md="6">
                    <Card.Body>
                        <h1>{t('processing.title')}</h1>
                        <br /><br />
                        <p>{t('processing.body')}</p>
                        <br />
                        {props.additionalApplicationCount < 40 ? //Show additional upload button if under 40 applications uploaded
                            <Button variant="primary" onClick={ () => reUploadFiles(history) }>
                                {t('processing.uploadButton')}
                            </Button> :
                            <p>{t('processing.uploadLimitMessage')}</p>
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>)
}

const reUploadFiles = (history) =>
{
  history.push({
    pathname: '/application',
    search: '?additionalUpload=true',
    state: { additionalUpload: true }
  })
}

const LoanTab = (props) => {

    //so this functions turns our UTC iso date into a normal mm/dd/yyyy format. This function also gives the formatted date for a next month 
    // if nextmonth is set to 1. Because every payment for Prosper is due on the 17th, day will always = 17 if nextmonth isn't 0. 
    function newGetFormatted(date, nextmonth) {
        let newdate = new Date(date)

        let year = newdate.getUTCFullYear();
        let month = (nextmonth + 1 + newdate.getUTCMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day;
        if (nextmonth)
            day = "17";
        else
            day = newdate.getUTCDate().toString();;

        day = day.length > 1 ? day : '0' + day;

        return month + '/' + day + '/' + year;
    }

    const [isvalid, setIsValid] = useState(true);
    if (props.userLoanInfo == null)
        setIsValid(false)

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.date')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{newGetFormatted(props.userLoanInfo[0].date, 0)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>

                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.loan')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{'$' + props.userLoanInfo[0].balance.toFixed(2)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>


                    <br />


                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.nextPaymentDate')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{newGetFormatted(props.userLoanInfo[props.userLoanInfo.length - 1].date, 1)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>
                    <br />

                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.lastPaymentDate')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{newGetFormatted(props.userLoanInfo[props.userLoanInfo.length - 1].date, 0)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>

                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.lastPaymentAmount')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{'$' + props.userLoanInfo[props.userLoanInfo.length - 1].payment.toFixed(2)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>




                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.lastLoanPrincipal')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{'$' + props.userLoanInfo[props.userLoanInfo.length - 1].principal.toFixed(2)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>

                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.interest_pmt')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{'$' + props.userLoanInfo[props.userLoanInfo.length - 1].selfInterest_pmt.toFixed(2)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>

                    <br />
                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.lprb')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{'$' + props.userLoanInfo[props.userLoanInfo.length - 1].balance.toFixed(2)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>

                    <Row className="userLoanInfoVariable">
                        <Col md={5}>
                            <p><strong>{props.t('userHome.interest_accr')}</strong></p>
                        </Col>
                        <Col md={2}>
                        {props.userLoanInfo.length > 0 ? 
                            <p>{'$' + props.userLoanInfo[props.userLoanInfo.length - 1].selfInterest_accr.toFixed(2)}</p>
                            :
                            <p>N/A</p>
                        }
                        </Col>
                    </Row>

                </Card.Body>
            </Card>
        </div>

    )
}

const AccountHome = (props) => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    if (props.userFields[0] === false)
        history.push('/login')

    else if (props.userFields[0] && props.userFields[1])
        history.push('/admin')

    else if (props.userFields[0] && !props.userFields[2] && !props.userFields[2] != null && !props.userFields[3])
        history.push('/application')

    else if (props.userFields[0] && props.userFields[2] && props.userFields[3] === false) {
        return (
            <ProcessesingApp username={props.userFields[4]} additionalApplicationCount={props.userFields[6]} />
        )
    }

    else if (props.userFields[0] === true && props.userFields[3] === true) {
        return (
            <>
                <Header setLanguage={setLanguage} username={props.userFields[4]} />
                <br />
                <div className="accounTabsBody">
                    <h1 className="qualifyTitle">{t('userHome.loanTitle')}</h1>
                    <Tabs defaultActiveKey="loan" transition={false} id="loanInfo">
                        <Tab eventKey="loan" title={t('userHome.myLoan')}>
                            <LoanTab t={t} userLoanInfo={props.userFields[5]} />
                        </Tab>
                        <Tab eventKey="account" title={t('userHome.settings')}>
                            <Card >
                                <Card.Body>
                                    <p>Coming soon...</p>
                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </>//
        )
    }

    return (null)
}
/*
const AccountSettings = () => {
    return (
        <div>
            <h1>Reset Password</h1>
            <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                    oldPassword: '',
                    newPassword: ''
                }}
            >


                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isSubmitting,
                    errors,
                }, ) => (
                        <Form onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>{t("createAcc.passwordFirst.value")}</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-key prefix"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Field name="password" type="Password"
                                            isInvalid={!!errors.password && touched.password} isValid={!!!errors.password && touched.password} as={Form.Control} />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Label>{t("createAcc.passwordSecond.value")}</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-key prefix"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name="passwordSecond"
                                            type="Password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={!!errors.passwordSecond && touched.passwordSecond}
                                            isValid={!!!errors.passwordSecond && touched.passwordSecond} />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.passwordSecond}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>
                            </Form.Row>


                            <Button disabled={isSubmitting} type="submit">{t('createAcc.create')}</Button>
                        </Form>
                    )}
            </Formik>
        </div>)
}*/




export default AccountHomeWrapper;
