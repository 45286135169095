import React, { useState, useEffect } from 'react';
import './CSS/App.css';
import QualificationsWrapper from "./components/Qualifications.js"
import CreateAccountWrapper from "./components/CreateAccount.js"
import AccountHomeWrapper from "./components/AccountHome.js"
import AdminHomeWrapper from "./components/AdminHome.js"
import LoginWrapper from "./components/Login.js"
import ResetPasswordWrapper from "./components/ResetPassword.js"
import ApplicationWrapper from './components/Application'
import DefaultPageWrapper from './components/DefaultPage'
//import { getTranslations } from './getTranslations'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'




const App = () => {

  return (
      
<Router>
  <Switch>
    <Route path="/CreateAccount" exact component={CreateAccountWrapper} />
    <Route path="/qualifications" exact component={QualificationsWrapper} />
    <Route path="/login" exact component={LoginWrapper} />
    <Route path="/home" exact component={AccountHomeWrapper} />
    <Route path="/application" exact component={ApplicationWrapper} />
    <Route path="/admin" exact component={AdminHomeWrapper} />
    <Route path='/resetPassword/:token' exact render={(props) => <ResetPasswordWrapper {...props} />} />
    <Route component={DefaultPageWrapper}/>
  </Switch>
</Router> 
  );
}
export default App;