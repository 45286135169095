import Auth from "./auth.js"
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import api from './api'


//this functions calls getting the website text for both Spanish and English versions (an admin is able to upload this site text and change it).
//Results are stored in localstorage and are recalled every 10 minutes. 
export function getTranslations() {

    return new Promise(function (resolve, reject) {
        let cookieobj = Auth.parseCookie();
        let defaultLang = "Eng";
        if (cookieobj.lang)
            defaultLang = cookieobj.lang

        let cacheEng = localStorage.getItem("eng")
        let cacheEsp = localStorage.getItem("esp")
        let whenCached = localStorage.getItem("time")
        let callGetTrans = true;


        if (cacheEng !== null && cacheEsp !== null && whenCached !== null) {
            let age = (Date.now() - whenCached) / 1000
            if (age < 600) {
                let engJson = JSON.parse(localStorage.getItem("eng"))
                let espJson = JSON.parse(localStorage.getItem("esp"))

                let resources = {
                    Eng: {
                        translation: engJson
                    },

                    Esp: {
                        translation: espJson
                    }
                };

                i18n
                    .use(initReactI18next) // passes i18n down to react-i18next
                    .init({
                        resources,
                        lng: defaultLang,
                        keySeparator: '.',
                        react: {
                            wait: true
                        }
                    }).then(()=>{resolve(true)})
                callGetTrans = false;
            }
            else {
                localStorage.removeItem("eng")
                localStorage.removeItem("esp")
                localStorage.removeItem("time")
            }
        }

        if (callGetTrans) {
            api.getTranslation().then(async res => {

                let resources = {
                    Eng: {
                        translation: res.data.eng
                    },

                    Esp: {
                        translation: res.data.esp
                    }
                };


                localStorage.setItem("eng", JSON.stringify(res.data.eng))
                localStorage.setItem("esp", JSON.stringify(res.data.esp))
                localStorage.setItem("time", Date.now())
                    i18n
                    .use(initReactI18next)
                    .init({
                        resources,
                        lng: defaultLang,
                        keySeparator: '.',
                        react: {
                            wait: true
                        }
                    }).then(()=>{ resolve(true)})
            }).catch(err => {
                console.log(err.response)
            })
        }

    })
}
