import React, { useState, useEffect } from "react";
import "../CSS/createAcc.css";
import Header from "./Header.js";
import api from "../api";
import { useHistory } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import {
  Alert,
  Card,
  Row,
  Col,
  Button,
  Modal,
  InputGroup,
  Form,
  Tab,
  Tabs,
  Spinner,
} from "react-bootstrap";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import "../CSS/AdminHome.css";
import { Formik, Field } from "formik";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getTranslations } from "../getTranslations";

var yup = require("yup");
var moment = require("moment");

function getFormattedDate(date) {
  let newdate = new Date(date);
  let year = newdate.getUTCFullYear();
  let month = (1 + newdate.getUTCMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  let day = newdate.getUTCDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return month + "/" + day + "/" + year;
}

const applicantColumns = [
  {
    Header: "New Loan Applications",
    headerClassName: "applicant-Header-Title",
    columns: [
      {
        Header: "First",
        accessor: "firstName",
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.05),
      },

      {
        Header: "Last",
        accessor: "lastName",
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.05),
      },

      {
        Header: "Email",
        accessor: "email",
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.1),
      },

      {
        Header: "Phone",
        accessor: "phoneNumber",
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.1),
      },

      {
        Header: "Application",
        Cell: (props) => {
          return <AppLinks application={props} />;
        },
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.1),
      },

      {
        Header: "Date Sent",
        accessor: "applicationDate",
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.1),
      },

      {
        Header: "Extra Files",
        accessor: "additionalApplications",
        Cell: (props) => {
          return (
            <div>
              {props.value &&
                props.value.map((fileName, i) => (
                  <AppLinks
                    key={fileName}
                    application={props}
                    fileName={fileName}
                    index={i + 1}
                  />
                ))}
            </div>
          );
        },
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.1),
      },

      {
        Header: "Review",
        Cell: (props) => {
          return <Review values={props} />;
        },
        style: {
          textAlign: "center",
        },
        width: Math.round(window.innerWidth * 0.1),
      },
    ],
  },
];

const UploadNewLoan = (props) => {
  const schema = yup.object({
    payment: yup
      .string()
      .required()
      .matches(
        /(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Wrong format"
      ),
    principal: yup
      .string()
      .required()
      .matches(
        /(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Wrong format"
      ),
  });

  const [submittingNewPayment, setSubmittingNewPayment] = useState(false);
  const handleUpload = (data) => {
    setSubmittingNewPayment(true);
    data.date = moment(loanDate)
      .add(moment(loanDate).utcOffset(), "m")
      .utc()
      .format();
    api
      .addLoan(data)
      .then((res) => {
        console.log(res);
        alert("Success!");
        setSubmittingNewPayment(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setSubmittingNewPayment(false);
        alert("Error Occured");
      });
  };
  const [loanDate, setLoanDate] = useState(null);
  const [showUploadLoan, setShowUploadLoan] = useState(false);
  let uploadLoan = (
    <Modal
      show={showUploadLoan}
      onHide={() => {
        setShowUploadLoan(false);
        setLoanDate(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Upload New Month Payment for:
          <br />{" "}
          {props.values.original.firstName +
            " " +
            props.values.original.lastName}
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema}
        onSubmit={handleUpload}
        initialValues={{
          payment: "",
          principal: "",
          username: props.values.original.username,
        }}
      >
        {({ handleSubmit, touched, isSubmitting, errors }) => (
          <div>
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Total Payment Amount</Form.Label>
                    <Field
                      name="payment"
                      type="input"
                      isInvalid={!!errors.payment && touched.payment}
                      isValid={!!!errors.payment && touched.payment}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.payment}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6">
                    <Form.Label>Principal Amount</Form.Label>
                    <Field
                      name="principal"
                      type="input"
                      isInvalid={!!errors.principal && touched.principal}
                      isValid={!!!errors.principal && touched.principal}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.principal}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Payment Date</Form.Label> <br />
                    <DatePicker
                      selected={loanDate}
                      onChange={(date) => setLoanDate(date)}
                      isClearable
                      placeholderText="MM-DD-YYYY"
                    />
                  </Form.Group>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  style={{ marginRight: "5%" }}
                  variant="success"
                  type="submit"
                >
                  {submittingNewPayment ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  Upload New Payment
                </Button>
                <Button
                  size="sm"
                  variant="secondary"
                  style={{ width: "90px" }}
                  onClick={() => {
                    setShowUploadLoan(false);
                    setLoanDate(null);
                  }}
                >
                  Exit
                </Button>
                <br />
              </Modal.Footer>
            </Form>
          </div>
        )}
      </Formik>
    </Modal>
  );

  return (
    <div>
      {showUploadLoan ? uploadLoan : null}
      <div className="row justify-content-center">
        <div style={{ display: "flex" }}>
          <Button
            size="sm"
            variant="outline-success"
            onClick={() => {
              setShowUploadLoan(true);
            }}
          >
            <i className="fas fa-file-upload"></i> Add
          </Button>
        </div>
      </div>
    </div>
  );
};

const DownloadIndivReportButton = (props) => {
  const handleDownloadIndiv = (e) => {
    e.preventDefault();
    let payload = { username: props.values.original.username };
    api
      .downloadIndivdReport(payload)
      .then((res) => {
        let saveByteArray = (function () {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          return function (data, name) {
            let blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
          };
        })();
        saveByteArray(
          res.data,
          props.values.original.firstName +
            "_" +
            props.values.original.lastName +
            "_Report.xlsx"
        );
      })
      .catch((err) => {
        console.log(err);
        alert("Error Occured");
      });
  };

  return (
    <Button
      size="sm"
      variant="outline-primary"
      style={{ marginRight: "10%" }}
      onClick={handleDownloadIndiv}
    >
      <i className="fas fa-file-download"></i>
    </Button>
  );
};

const DeletePaymentButton = (props) => {
  const [submittingDelete, setSubmittingDelete] = useState(false);
  const handleDeletePayment = (e) => {
    setSubmittingDelete(true);
    e.preventDefault();
    let payload = {
      username: props.user.username,
      date: props.cellprops.original.date,
    };
    api
      .deleteLoan(payload)
      .then((res) => {
        console.log(res);
        alert("Successfully deleted payment");
        window.location.reload();
        setSubmittingDelete(false);
      })
      .catch((error) => {
        alert("unknown error, check console");
        console.log(error.response);
        setSubmittingDelete(false);
      });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deletePayment = (
    <Modal
      show={showDeleteModal}
      onHide={() => {
        setShowDeleteModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you'd like to delete this payment for{" "}
          <strong>
            {props.user.firstName + " " + props.user.lastName + " "}
          </strong>
          on {getFormattedDate(props.cellprops.original.date)}?
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleDeletePayment}>
        <Modal.Body>
          This will delete this specific payment off the database
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: "5%" }}
            type="submit"
            variant="danger"
            size="sm"
          >
            {submittingDelete ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Yes, Delete it
          </Button>
          <Button
            style={{ width: "60px" }}
            variant="secondary"
            size="sm"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Exit
          </Button>{" "}
        </Modal.Footer>
      </Form>
    </Modal>
  );

  return (
    <div>
      {showDeleteModal ? deletePayment : null}
      <Button
        size="sm"
        variant="danger"
        style={{ marginRight: "10%" }}
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <i className="fas fa-trash-alt"></i> Delete
      </Button>
    </div>
  );
};

const EraseLoanData = (props) => {
  console.log(props);
  const [submittingDelete, setSubmittingDelete] = useState(false);
  const handleDeletePayment = (e) => {
    setSubmittingDelete(true);
    e.preventDefault();
    let payload = { username: props.selectedUser.username };
    api
      .clearUserLoan(payload)
      .then((res) => {
        alert("Successfully deleted payment");
        window.location.reload();
        setSubmittingDelete(false);
      })
      .catch((error) => {
        alert("unknown error, check console");
        console.log(error.response);
        setSubmittingDelete(false);
      });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deletePayment = (
    <Modal
      show={showDeleteModal}
      onHide={() => {
        setShowDeleteModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you'd like to erase all loan data for{" "}
          <strong>
            {props.selectedUser.firstName +
              " " +
              props.selectedUser.lastName +
              " "}
          </strong>
          ?
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleDeletePayment}>
        <Modal.Body>This will delete all loan data for this user.</Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginRight: "5%" }}
            type="submit"
            variant="danger"
            size="sm"
          >
            {submittingDelete ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Yes, erase it
          </Button>
          <Button
            style={{ width: "60px" }}
            size="sm"
            variant="secondary"
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            Exit
          </Button>{" "}
        </Modal.Footer>
      </Form>
    </Modal>
  );

  return (
    <div>
      {showDeleteModal ? deletePayment : null}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ marginBottom: "0px" }}>
          {props.selectedUser.firstName +
            " " +
            props.selectedUser.lastName +
            " (" +
            props.selectedUser.username +
            ")"}
        </p>
        <Button
          size="sm"
          variant="danger"
          style={{ lineHeight: "10px", marginLeft: "3%" }}
          onClick={() => {
            setShowDeleteModal(true);
          }}
        >
          <i className="fas fa-eraser"></i>Erase
        </Button>
      </div>
    </div>
  );
};

const AppLinks = (props) => {
  const getAppLink = (e) => {
    e.preventDefault();
    const payload = {
      username: props.application.original.username,
      filename: props.fileName,
    };
    api.getApplicationLink(payload).then((res) => {
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = res.data.data;
      a.click();
    });
  };

  return (
    <Button
      size="sm"
      variant="outline-primary"
      onClick={getAppLink}
      style={{ marginRight: "2px" }}
    >
      {props.index ? props.index : "Application"}
    </Button>
  );
};

const Review = (props) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);

  const schema = yup.object({
    balance: yup
      .string()
      .required()
      .matches(
        /(?=.*?\d)^(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Wrong format"
      ),
  });

  const handleSubmit = (data, { setSubmitting }) => {
    setSubmitting(true);
    data.additionalFiles = props.values.original.additionalApplications;
    api
      .setQualified(data)
      .then((res) => {
        setSubmitting(false);
        if (res.data.success) {
          setShowAcceptModal(false);
          alert("Successfully Created Loan!");
          window.location.reload();
        }
      })
      .catch((error) => {
        setShowAcceptModal(false);
        setSubmitting(false);
        alert("There was an error");
        console.log(error);
      });
  };

  const [submittingDeny, setSubmittingDeny] = useState(false);
  const handleSubmitDeny = (e) => {
    setSubmittingDeny(true);
    e.preventDefault();

    let payload = {
      username: props.values.original.username,
      additionalFiles: props.values.original.additionalApplications,
    };
    api
      .adminDelete(payload)
      .then((res) => {
        setShowDenyModal(false);
        alert("User was deleted");
        window.location.reload();
      })
      .catch((error) => {
        setSubmittingDeny(false);
        setShowDenyModal(false);
        alert("There was an error");
        console.log(error);
      });
  };

  const denyLoan = (
    <Modal
      show={showDenyModal}
      onHide={() => {
        setShowDenyModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you'd like to deny a loan for{" "}
          <strong>
            {props.values.original.firstName +
              " " +
              props.values.original.lastName +
              "?"}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmitDeny}>
        <Modal.Body>
          <strong>THIS WILL DELETE THEIR ACCOUNT</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="danger"
            style={{ marginRight: "5%" }}
            size="sm"
          >
            {submittingDeny ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Yes, Deny them
          </Button>
          <Button
            variant="secondary"
            size="sm"
            style={{ width: "60px" }}
            onClick={() => {
              setShowDenyModal(false);
            }}
          >
            Exit
          </Button>{" "}
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const acceptLoan = (
    <Modal
      show={showAcceptModal}
      onHide={() => {
        setShowAcceptModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you'd like to create a new loan for{" "}
          <strong>
            {props.values.original.firstName +
              " " +
              props.values.original.lastName +
              "?"}
          </strong>
        </Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={{
          balance: "",
          username: props.values.original.username,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isSubmitting,
          errors,
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <p>{props.values.original.username}</p>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Loan Amount</Form.Label>
                    <Field
                      name="balance"
                      type="input"
                      isInvalid={!!errors.balance && touched.balance}
                      isValid={!!!errors.balance && touched.balance}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.balance}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {/*<Form.Group as={Col} md="6" >
                                            <Form.Label>Self-Interest Percent</Form.Label>
                                            <Field name="selfInterest" type="input"
                                                isInvalid={!!errors.selfInterest && touched.selfInterest} isValid={!!!errors.selfInterest && touched.selfInterest} as={Form.Control} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.selfInterest}
                                            </Form.Control.Feedback>
                                            </Form.Group>*/}
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="success"
                  type="submit"
                  disabled={isSubmitting}
                  size="sm"
                  style={{ marginRight: "5%" }}
                >
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  Create New Loan
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowAcceptModal(false);
                  }}
                  size="sm"
                  style={{ width: "60px" }}
                >
                  Exit
                </Button>
              </Modal.Footer>
            </Form>
          </> //
        )}
      </Formik>
    </Modal>
  );

  return (
    <div className="row justify-content-center">
      <div style={{ display: "flex" }}>
        {showAcceptModal ? acceptLoan : null}
        {showDenyModal ? denyLoan : null}
        <Button
          size="sm"
          variant="success"
          onClick={() => {
            setShowAcceptModal(true);
          }}
          className="adminReviewAcceptButton"
        >
          Accept
        </Button>
        <Button
          size="sm"
          variant="danger"
          onClick={() => {
            setShowDenyModal(true);
          }}
          className="adminReviewDenyButton"
        >
          Deny
        </Button>
      </div>
    </div>
  );
};

const AdminPanel = (props) => {
  const { t, i18n } = useTranslation();
  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const [selectedUserLaon, setSelectedUserLoan] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const loanColumns = [
    {
      Header: "Active Loans",
      headerClassName: "borrower-Header-Title",
      columns: [
        {
          Header: "First",
          accessor: "firstName",
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.05),
        },

        {
          Header: "Last",
          accessor: "lastName",
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.05),
        },

        {
          Header: "Email",
          accessor: "username",
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },

        {
          Header: "Phone",
          accessor: "phoneNumber",
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
        },

        {
          Header: "Last Pay Amnt",
          id: "LastPayAmnt",
          accessor: (a) =>
            a.loan_information.length > 0
              ? "$" +
                a.loan_information[
                  a.loan_information.length - 1
                ].payment.toFixed(2)
              : null,
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
        },

        {
          Header: "Last Pay Date",
          id: "LastPayDate",
          accessor: (a) =>
            a.loan_information.length > 0
              ? getFormattedDate(
                  a.loan_information[a.loan_information.length - 1].date
                )
              : null,
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
          sortMethod: (a, b) => {
            if (Date.parse(a) < Date.parse(b)) {
              return 1;
            }
            return -1;
          },
        },

        {
          Header: "Current Balance",
          id: "Balance",
          accessor: (a) =>
            a.loan_information.length > 0
              ? "$" +
                a.loan_information[
                  a.loan_information.length - 1
                ].balance.toFixed(2)
              : null,
          style: {
            textAlign: "center",
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
        },

        {
          Header: "Payment Report",
          Cell: (props) => {
            return (
              <div className="row justify-content-center">
                <div style={{ display: "flex" }}>
                  <DownloadIndivReportButton values={props} />
                  <Button
                    variant="outline-info"
                    size="sm"
                    onClick={() => {
                      setSelectedUserLoan(props.original.loan_information);
                      setSelectedUser(props.original);
                    }}
                  >
                    <i className="fas fa-eye"></i> View
                  </Button>
                </div>
              </div>
            );
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },

        {
          Header: "New Payment",
          Cell: (props) => {
            return <UploadNewLoan values={props} />;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
        },
      ],
    },
  ];

  const individualCols = [
    {
      Header: (props) => {
        return <EraseLoanData selectedUser={selectedUser} />;
      },
      headerClassName: "borrower-Header-Title",
      columns: [
        {
          Header: "Date",
          Cell: (props) => {
            return <p>{getFormattedDate(props.original.date)}</p>;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },

        {
          Header: "Total Payment",
          Cell: (props) => {
            return <p>${props.original.payment.toFixed(2)}</p>;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },

        {
          Header: "Principal pay",
          Cell: (props) => {
            return <p>${props.original.principal.toFixed(2)}</p>;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },

        {
          Header: "Self-interest pay",
          Cell: (props) => {
            return <p>${props.original.selfInterest_pmt.toFixed(2)}</p>;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
        },

        {
          Header: "Interest Accrued",
          Cell: (props) => {
            return <p>${props.original.selfInterest_accr.toFixed(2)}</p>;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.07),
        },

        {
          Header: "Balance",
          Cell: (props) => {
            return <p>${props.original.balance.toFixed(2)}</p>;
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },

        {
          Header: "Delete Payment",
          Cell: (props) => {
            return (
              <DeletePaymentButton cellprops={props} user={selectedUser} />
            );
          },
          style: {
            textAlign: "center",
          },
          width: Math.round(window.innerWidth * 0.1),
        },
      ],
    },
  ];

  //userdata is all applicant related data, loandata is all borrower related data
  const [userdata, setUserData] = useState([]);
  const [loanData, setLoanData] = useState([]);

  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    api.getUsers().then((res) => {
      let totalArr = res.data.data;
      let applicantArray = [];
      let activeLoanArray = [];
      let totalBalance = 0;
      for (let i = 0; i < totalArr.length; i++) {
        if (
          totalArr[i].applicationSent === true &&
          totalArr[i].qualified === false
        ) {
          totalArr[i].applicationDate = getFormattedDate(
            totalArr[i].applicationDate
          );
          applicantArray.push(totalArr[i]);
        } else if (totalArr[i].qualified === true) {
          activeLoanArray.push(totalArr[i]);
          if (totalArr[i].loan_information.length > 0) {
            totalBalance +=
              totalArr[i].loan_information[
                totalArr[i].loan_information.length - 1
              ].balance;
          }
        }
      }
      setUserData(applicantArray);
      setLoanData(activeLoanArray);
      setTotalBalance(totalBalance);
    });
  }, []);

  const [submittingOverallReport, setSubmittingOverallReport] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittingOverallReport(true);
    let formData = new FormData();
    formData.append("file", e.target[0].files[0]);
    api
      .uploadReport(formData)
      .then((res) => {
        alert("Successfully uploaded report!");
        setShowUploadAllModal(false);
        setSubmittingOverallReport(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response);
        alert("Something went wrong...");
        setShowUploadAllModal(false);
        setSubmittingOverallReport(false);
      });
  };

  const [showSuccess, setSuccess] = useState(false);
  const [errorCode, setErrorCode] = useState(0);

  let successModal = <Modal
  show={showSuccess}
  onHide={() => {
    setSuccess(false);
  }}
>
  <Modal.Header>
                    <Modal.Title>{"Account Successfully Created!"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{"The client may now login with their email address and password."}</Modal.Body>
</Modal>

  let errorAlert =
    errorCode === 1 ? (
      <Alert variant="danger">
        <Alert.Heading>{t("createAcc.response.userExists")}</Alert.Heading>
        <p>{t("createAcc.response.userExistsInfo")}</p>
      </Alert>
    ) : (
      <Alert variant="danger">
        <Alert.Heading>{t("createAcc.response.unkown")}</Alert.Heading>
        <p>{t("createAcc.response.unkownInfo")}</p>
      </Alert>
    );

  const handleAddNewLoan = async (data, {setSubmitting}) => {
      setSubmitting(true);
      const balance = data.loanAmount;
      
      const loanInfo = [{
        "date": new Date(),
        "payment": 0,
        "principal": 0,
        "balance": balance,
        "selfInterest_pmt": 0,
        "selfInterest_accr": 0
      }];
      
      data.username = data.email;
      data.loan_information = loanInfo;
      data.qualified = true;

    await api
      .insertUser(data)
      .then((res) => {
        setSubmitting(false);
        if (res.data.success) {
          setShowAddNewLoanModal(false);
          setSuccess(true);
        }
        else alert("error occurred");
      })
      .catch((error) => {
        if (error.response.data) {
          setSubmitting(false);
          if (error.response.data.code === 1) setErrorCode(1);
          else setErrorCode(2);
        }
      });
  };

  const validName = (string) => /[.*+?^${}()|[\]\\<>~#%&:\/\s]/.test(string);

  const newUserSchema = yup.object({
    firstName: yup
      .string()
      .required(t("createAcc.firstName.required_E"))
      .test(
        t("createAcc.firstName.matches_E"),
        t("createAcc.firstName.matches_E"),
        (value) => !validName(value)
      )
      .max(64, t("createAcc.firstName.max_E")),

    lastName: yup
      .string()
      .required(t("createAcc.lastName.required_E"))
      .test(
        t("createAcc.lastName.matches_E"),
        t("createAcc.lastName.matches_E"),
        (value) => !validName(value)
      )
      .max(64, t("createAcc.lastName.max_E")),

    email: yup
      .string()
      .required(t("createAcc.email.required_E"))
      .matches(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
        t("createAcc.email.matches_E")
      )
      .max(64, t("createAcc.email.max_E")),

    phoneNumber: yup
      .string()
      .required(t("createAcc.phone.required_E"))
      .matches(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
        t("createAcc.phone.matches_E")
      )
      .max(64, t("createAcc.phone.max_E")),

    loanAmount: yup
      .string()
      .required("Loan Amount Required")
      .matches(/^[0-9]+$/,
        "Invalid input")
  });


  const [showAddNewLoanModal, setShowAddNewLoanModal] = useState(false);
  let addNewLoan = (
    <Modal
      show={showAddNewLoanModal}
      onHide={() => {
        setShowAddNewLoanModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create Account for New Loan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={newUserSchema}
          onSubmit={handleAddNewLoan}
          initialValues={{
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            loanAmount: "",
            password: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isSubmitting,
            errors,
          }) => (
            <Form onSubmit={handleSubmit}>
              <h1 className="createAccountTitle">{"Create Account"}</h1>
              <br />
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>{"First name"}</Form.Label>
                  <Field
                    name="firstName"
                    type="input"
                    id="name"
                    placeholder={"Enter First Name"}
                    isInvalid={!!errors.firstName && touched.firstName}
                    isValid={!!!errors.firstName && touched.firstName}
                    as={Form.Control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>{"Last name"}</Form.Label>
                  <Field
                    name="lastName"
                    type="input"
                    placeholder={"Enter Last Name"}
                    isInvalid={!!errors.lastName && touched.lastName}
                    isValid={!!!errors.lastName && touched.lastName}
                    as={Form.Control}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>{"Phone Number"}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <i className="fa fa-phone"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      name="phoneNumber"
                      type="Phone"
                      placeholder={"Enter Phone Number"}
                      isInvalid={!!errors.phoneNumber && touched.phoneNumber}
                      isValid={!!!errors.phoneNumber && touched.phoneNumber}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>{"Loan Amount"}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <i className="fa fa-dollar-sign"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      name="loanAmount"
                      type="input"
                      placeholder={"Enter Loan Amount"}
                      isInvalid={!!errors.loanAmount && touched.loanAmount}
                      isValid={!!!errors.loanAmount && touched.loanAmount}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.loanAmount}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>{"Email address"}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <i className="fa fa-envelope"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      name="email"
                      type="input"
                      placeholder={"Enter Email"}
                      isInvalid={!!errors.email && touched.email}
                      isValid={!!!errors.email && touched.email}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <Form.Text className="text-muted">
                    {"Client Should use this to login"}
                  </Form.Text>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>{"Password"}</Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        <i className="fa fa-key prefix"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Field
                      name="password"
                      type="Password"
                      placeholder={"Enter Password"}
                      isInvalid={!!errors.password && touched.password}
                      isValid={!!!errors.password && touched.password}
                      as={Form.Control}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                  <Form.Text className="text-muted">
                    {"Create a password for the client."}
                  </Form.Text>
                </Form.Group>
              </Form.Row>
              <Button
          size="sm"
          variant="success"
          style={{ marginRight: "5%" }}
          type="submit"
        >
          {"Create Account"}
        </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button
          size="sm"
          variant="success"
          style={{ marginRight: "5%" }}
          type="submit"
        >
          {"Create Account"}
        </Button>
      </Modal.Footer> */}
    </Modal>
  );

  const [showUploadAllModal, setShowUploadAllModal] = useState(false);
  let uploadOverall = (
    <Modal
      show={showUploadAllModal}
      onHide={() => {
        setShowUploadAllModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload overall report</Modal.Title>
      </Modal.Header>
      <Form encType="multipart/form-data" onSubmit={handleSubmit}>
        <Modal.Body>
          <p>Uplaod an excel file in the correct format only</p>
          <div>
            <input type="file" id="customFile" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="success"
            type="submit"
            style={{ marginRight: "5%" }}
          >
            {submittingOverallReport ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Upload Report
          </Button>
          <Button
            size="sm"
            variant="secondary"
            style={{ width: "60px" }}
            onClick={() => {
              setShowUploadAllModal(false);
            }}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const handleSubmitDownload = (e) => {
    e.preventDefault();
    let payload = {
      dateBegin: moment(startDate)
        .add(moment(startDate).utcOffset(), "m")
        .utc()
        .format(),
      dateEnd: moment(endDate)
        .add(moment(endDate).utcOffset(), "m")
        .utc()
        .format(),
    };
    setSubmittingDownload(true);
    let ex = new Date(payload.dateBegin);
    api
      .downloadOverallReport(payload)
      .then((res) => {
        let saveByteArray = (function () {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          return function (data, name) {
            let blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = name;
            a.click();
            window.URL.revokeObjectURL(url);
          };
        })();
        saveByteArray(
          res.data,
          "Report " +
            getFormattedDate(startDate) +
            "-" +
            getFormattedDate(endDate) +
            ".xlsx"
        );
        setSubmittingDownload(false);
        setShowDownloadAllModal(false);
      })
      .catch((error) => {
        console.log(error);
        alert("Error Occured");
        setShowDownloadAllModal(false);
        setSubmittingDownload(false);
      });
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDownloadAllModal, setShowDownloadAllModal] = useState(false);
  const [submittingDownload, setSubmittingDownload] = useState(false);
  let downloadOverall = (
    <Modal
      show={showDownloadAllModal}
      onHide={() => {
        setShowDownloadAllModal(false);
        setStartDate(null);
        setEndDate(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Download overall report</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmitDownload}>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <p>Enter the start date and end date for the report</p>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                isClearable
                placeholderText="MM-DD-YYYY"
                maxDate={endDate}
              />
            </Form.Group>

            <Form.Group as={Col} md="6">
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                isClearable
                placeholderText="MM-DD-YYYY"
                minDate={startDate}
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="success"
            size="sm"
            type="submit"
            style={{ marginRight: "5%" }}
          >
            {submittingDownload ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Download
          </Button>
          <Button
            style={{ width: "60px" }}
            size="sm"
            variant="secondary"
            onClick={() => {
              setShowDownloadAllModal(false);
              setStartDate(null);
              setEndDate(null);
            }}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const [submittingEnglishText, SetSubmittingEnglishText] = useState(false);
  const handleSubmitEnglish = (e) => {
    e.preventDefault();
    SetSubmittingEnglishText(true);
    let formData = new FormData();
    formData.append("file", e.target[0].files[0]);

    api
      .uploadEngTranslation(formData)
      .then((res) => {
        alert("Successfully uploaded report!");
        setShowUploadEnglish(false);
        SetSubmittingEnglishText(false);
      })
      .catch((err) => {
        console.log(err.response);
        alert("Something went wrong...");
        setShowUploadEnglish(false);
        SetSubmittingEnglishText(false);
      });
  };

  const [showUploadEnglish, setShowUploadEnglish] = useState(false);
  let uploadEnglish = (
    <Modal
      show={showUploadEnglish}
      onHide={() => {
        setShowUploadEnglish(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload English Text</Modal.Title>
      </Modal.Header>
      <Form encType="multipart/form-data" onSubmit={handleSubmitEnglish}>
        <Modal.Body>
          <p>
            Uplaod the English .JSON file. Changes are visible every 10 minutes.
            You can force this instantly by deleteing the localstorage in your
            browser.
          </p>
          <div>
            <input type="file" id="customFile" accept=".json" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="success"
            type="submit"
            style={{ marginRight: "5%" }}
          >
            {submittingEnglishText ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Upload Text
          </Button>
          <Button
            size="sm"
            variant="secondary"
            style={{ width: "60px" }}
            onClick={() => {
              setShowUploadEnglish(false);
            }}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  const [submittingSpanishText, SetSubmittingSpanishText] = useState(false);
  const handleSubmitSpanish = (e) => {
    e.preventDefault();
    SetSubmittingSpanishText(true);
    let formData = new FormData();
    formData.append("file", e.target[0].files[0]);
    console.log(formData);

    api
      .uploadEspTranslation(formData)
      .then((res) => {
        alert("Successfully uploaded report!");
        SetSubmittingSpanishText(false);
        setShowUploadSpanish(false);
      })
      .catch((err) => {
        console.log(err.response);
        alert("Something went wrong...");
        SetSubmittingSpanishText(false);
        setShowUploadSpanish(false);
      });
  };

  const [showUploadSpanish, setShowUploadSpanish] = useState(false);
  let uploadSpanish = (
    <Modal
      show={showUploadSpanish}
      onHide={() => {
        setShowUploadSpanish(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Spanish Text</Modal.Title>
      </Modal.Header>
      <Form encType="multipart/form-data" onSubmit={handleSubmitSpanish}>
        <Modal.Body>
          <p>
            Uplaod the Spanish .JSON file. Changes are visible every 10 minutes.
            You can force this instantly by deleteing the localstorage in your
            browser.
          </p>
          <div>
            <input type="file" id="customFile" accept=".json" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="success"
            type="submit"
            style={{ marginRight: "5%" }}
          >
            {submittingSpanishText ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Upload Text
          </Button>
          <Button
            size="sm"
            variant="secondary"
            style={{ width: "60px" }}
            onClick={() => {
              setShowUploadSpanish(false);
            }}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );

  //preserves a tab accross refresh/leaving by using localstorage.
  const [defaultTab, setDefaultTab] = useState(
    localStorage.getItem("tab") == null
      ? "Applicants"
      : localStorage.getItem("tab")
  );

  return (
    <div>
      <Header setLanguage={setLanguage} username={props.username} />
      <h1 align="center">Admin Panel</h1>

      <div align="center">
        {showAddNewLoanModal ? addNewLoan : null}
        {showSuccess ? successModal : null}
        {errorCode !== 0 ? errorAlert : null}
        {showUploadAllModal ? uploadOverall : null}
        {showDownloadAllModal ? downloadOverall : null}
        {showUploadEnglish ? uploadEnglish : null}
        {showUploadSpanish ? uploadSpanish : null}
        <Row>
          <Col md="2">
            <Card style={{ marginLeft: "10%" }}>
              <Card.Body className="adminSideInfoBody">
                <div className="adminSideInfoInnerDiv">
                  <p>
                    <strong>Number of Applications:</strong>
                  </p>
                  <p>{userdata.length}</p>
                </div>
                <br />
                <div className="adminSideInfoInnerDiv">
                  <p>
                    <strong>Number of Active loans:</strong>
                  </p>
                  <p>{loanData.length}</p>
                </div>
                <br />
                <div className="adminSideInfoInnerDiv">
                  <p>
                    <strong>Total Active Balance:</strong>
                  </p>
                  <p>${totalBalance.toFixed(2)}</p>
                </div>
                <br />
                <br />
                <div className="adminSideInfoInnerDiv">
                  <p>
                    <strong>New Loans</strong>
                  </p>
                  <Button
                    variant="outline-success"
                    style={{ marginBottom: "3%", width: "90%" }}
                    onClick={() => {
                      setShowAddNewLoanModal(true);
                    }}
                  >
                    <i className="fas fa-file-upload"></i>Add New Loan
                  </Button>
                  <br />
                  <br />
                </div>
                <br />
                <br />
                <div className="adminSideInfoInnerDiv">
                  <p>
                    <strong>Payment Reports</strong>
                  </p>
                  <Button
                    variant="outline-success"
                    style={{ marginBottom: "3%", width: "90%" }}
                    onClick={() => {
                      setShowUploadAllModal(true);
                    }}
                  >
                    <i className="fas fa-file-upload"></i> Upload Overall
                    Payment Report
                  </Button>
                  <br />
                  <br />
                  <Button
                    variant="outline-primary"
                    style={{ marginBottom: "3%", width: "90%" }}
                    onClick={() => {
                      setShowDownloadAllModal(true);
                    }}
                  >
                    <i className="fas fa-file-download"></i> Download Overall
                    Payment Report
                  </Button>
                </div>
                <br />
                <br />
                <div className="adminSideInfoInnerDiv">
                  <p>
                    <strong>Updating Info</strong>
                  </p>
                  <Button
                    variant="outline-success"
                    style={{ marginBottom: "3%", width: "90%" }}
                    onClick={() => {
                      setShowUploadEnglish(true);
                    }}
                  >
                    <i className="fas fa-file-upload"></i> Upload English Site
                    Text
                  </Button>
                  <br />
                  <br />
                  <Button
                    variant="outline-success"
                    style={{ marginBottom: "3%", width: "90%" }}
                    onClick={() => {
                      setShowUploadSpanish(true);
                    }}
                  >
                    <i className="fas fa-file-upload"></i> Upload Spanish Site
                    Text
                  </Button>
                  <br />
                  <br />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="8">
            <Tabs
              defaultActiveKey={defaultTab}
              transition={false}
              onSelect={(e) => {
                localStorage.setItem("tab", e);
              }}
            >
              <Tab eventKey="Applicants" title="Applicants" id="tabNavi">
                <ReactTable
                  columns={applicantColumns}
                  data={userdata}
                  defaultPageSize={10}
                />
              </Tab>
              <Tab eventKey="Borrowers" title={"Borrowers"}>
                <ReactTable
                  columns={loanColumns}
                  data={loanData}
                  defaultPageSize={10}
                />
              </Tab>
            </Tabs>
            <br />
            {selectedUserLaon.length > 0 ? (
              <ReactTable
                columns={individualCols}
                data={selectedUserLaon}
                defaultPageSize={10}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    </div>
  );
};
const AdminHomeWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info
  const [userFields, setUserFields] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  useEffect(() => {
    getTranslations()
      .then((res) => {
        setGotTrans(res);
      })
      .catch((err) => {
        console.log(err);
        setGotTrans(false);
      });
    api
      .getUserBySession()
      .then((res) => {
        let tempFields = [...userFields];
        tempFields[0] = true;
        tempFields[1] = res.data.user.admin;
        tempFields[2] = res.data.user.applicationSent;
        tempFields[3] = res.data.user.qualified;
        tempFields[4] = res.data.user.username;
        tempFields[5] = res.data.user.loan_information;
        setUserFields(tempFields);
      })
      .catch((error) => {
        let tempFields = [...userFields];
        tempFields[0] = false;
        setUserFields(tempFields);
      });
  }, []);

  return (
    <div>
      {gotTrans && userFields[0] !== null ? (
        <AdminHome userFields={userFields} />
      ) : null}
    </div>
  );
};

const AdminHome = (props) => {
  const history = useHistory();
  if (props.userFields[0] === false && props.userFields[0] != null)
    history.push("/login");
  else if (
    props.userFields[0] &&
    (props.userFields[3] || props.userFields[2]) &&
    props.userFields[1] === false
  )
    history.push("/home");
  else if (
    props.userFields[0] &&
    !props.userFields[2] &&
    props.userFields[2] != null &&
    props.userFields[1] === false
  )
    history.push("/application");
  else if (props.userFields[0] && props.userFields[1])
    return <AdminPanel username={props.userFields[4]} />;

  return null;
};

export default AdminHomeWrapper;
