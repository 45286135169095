import React, { useState, useEffect } from 'react';
import Header from "./Header.js"
import api from '../api'
import { useHistory } from 'react-router-dom';
import "../CSS/Application.css"
import '../pages/i18n.js';
import { useTranslation } from "react-i18next";
import { Button, Form, Card, Col, Spinner } from 'react-bootstrap';
import { getTranslations } from '../getTranslations'

//this is used to edit the template of the krajee file uploader I use. The defualt includes a seperate uplaod button per file that I didn't want.
const actionsTemplate = '<div class="file-actions">\n' +
    '    <div class="file-footer-buttons">\n' +
    '        {download} {delete} {zoom} {other}' +
    '    </div>\n' +
    '    {drag}\n' +
    '    <div class="clearfix"></div>\n' +
    '</div>';


const ApplicatonWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info [6]=application reupload
  const [userFields, setUserFields] = useState([null, null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
        api.getUserBySession().then(res => {
            let tempFields = [...userFields]
            tempFields[0] = true;
            tempFields[1] = res.data.user.admin
            tempFields[2] = res.data.user.applicationSent
            tempFields[3] = res.data.user.qualified
            tempFields[4] = res.data.user.username
            tempFields[5] = res.data.user.loan_information

            let additionalUploadField = (props.location.state && props.location.state["additionalUpload"])
                ? true : false
            console.log("Additional Upload field identified as: " + additionalUploadField)
            tempFields[6] = additionalUploadField

            setUserFields(tempFields)


        }).catch((error) => {
            let tempFields = [...userFields]
            tempFields[0] = false;
            setUserFields(tempFields)
        })
    }, [])

    return (
        <div>
            {
                gotTrans && userFields[0] !== null ?
                    <Application userFields={userFields} />
                    :
                    null
            }
        </div>
    )

}



const FileUpload = (props) => {
    const [submitting, setSubmitting] = useState(false)
    const history = useHistory();


    //This plugin (krajee file upload) automatically posts to the specified URL, but it posts the files as binary type. I couldn't figure out how to make ti post FormData
    //and a specified uploadURL is required to use features such as drag and drop. The uploadURL is just a dummy URL and isn't used.
    //You will notice on the form tags itself there's no submit either, it's an onlick event for the button to submit. I extract the file objects
    //from the krajee file uploader into filearray and convert that into formdata then post manually. It's what one may call 'jank'
    const [fileCount, setFileCount] = useState(0)
    useEffect(() => {
        window.$("#input-fcount-1").fileinput({
            layoutTemplates: { actions: actionsTemplate },
            uploadUrl: process.env.REACT_APP_POST_URL + "/api/uploadFile",
            showClose: false,
            showCancel: false,
            showUpload: false,
            maxFileSize: 250000,
            mainClass: "input-group-lg",
            ajaxSettings: {
                withCredentials: true,
                processData: false,
                ContentType: 'multipart/form-data',
                xhrFields: {
                    withCredentials: true,
                },
                crossDomain: true
            },
            uploadAsync: false,
            maxFileCount: 20,
            overwriteInitial: false,
            allowedFileExtensions: ["jpg", "jpeg", "png", "pdf"]
        });

        window.$('#input-fcount-1').on('filebatchuploadsuccess', function (event, data) {
            history.push('/home')
        });

        window.$('#input-fcount-1').on('fileselect', function() {
            console.log("fileselect: ", window.$('#input-fcount-1').fileinput('getFilesCount'))
            setFileCount(window.$('#input-fcount-1').fileinput('getFilesCount'))
        });

        window.$('#input-fcount-1').on('filereset', function() {
            console.log("filerest: ", window.$('#input-fcount-1').fileinput('getFilesCount'))
            setFileCount(window.$('#input-fcount-1').fileinput('getFilesCount'))
        });


        window.$('#input-fcount-1').on('fileremoved', function() {
            console.log("fileremoved: ", window.$('#input-fcount-1').fileinput('getFilesCount'))
            setFileCount(window.$('#input-fcount-1').fileinput('getFilesCount'))
        });

        window.$('#input-fcount-1').on('filecleared', function() {
            console.log("filecleared: ", window.$('#input-fcount-1').fileinput('getFilesCount'))
            setFileCount(window.$('#input-fcount-1').fileinput('getFilesCount'))
        });

        window.$('#input-fcount-1').on('filecleared', function() {
            setFileCount(window.$('#input-fcount-1').fileinput('getFilesCount'))
        });




    }, [])


    const callSubmitFiles = () => {
        setSubmitting(true)
        window.$('#input-fcount-1').fileinput('upload');

    }

    return (
        <Form encType="multipart/form-data">
            <div className="file-loading">
                <input id="input-fcount-1" name="file" multiple type="file" data-theme="fas" />
            </div>
            <br />

            <Button onClick={callSubmitFiles} className="applicationSubmitButton" disabled={fileCount < 1 || fileCount > 9 || submitting}>
                {submitting ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}
                {props.t("application.submit")}</Button>
        </Form>
    )
}

const Application = (props) => {

    const { t, i18n } = useTranslation();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const history = useHistory();

    const isAdditionalDocUpload = props.userFields[6]

    if (props.userFields[0] === false && props.userFields[0] != null)
        history.push('/login')

    else if (props.userFields[0] && props.userFields[1])
        history.push('/admin')

    else if (props.userFields[0] && (props.userFields[3] || props.userFields[2]) && !isAdditionalDocUpload)
        history.push('/home')

    else if (props.userFields[0] && isAdditionalDocUpload || (!props.userFields[2] && !props.userFields[2] != null)) {
        return (
            <div >
                <Header setLanguage={setLanguage} username={props.userFields[4]} />
                <div className="row justify-content-center">
                    <Card as={Col} md="6">
                        <Card.Body>
                            <h1 className="applicationTitle">
                                {isAdditionalDocUpload
                                  ? t("application.titleAdditionalUpload")
                                  : t("application.title")}
                            </h1>
                            <br />
                            <p>{isAdditionalDocUpload && t("application.introAdditionalUpload")}</p>
                            {isAdditionalDocUpload && <br />}
                            <p><b>{isAdditionalDocUpload && t("application.additionalBodyPrefix")}</b>{t("application.body")}</p>
                            <p>{t("application.body2")}</p><br />
                            <a href="https://www.wikihow.com/Save-a-Fillable-PDF-on-Google-Chrome" target="_blank"><strong>{t("application.instruction")}</strong></a><br /><br />
                            <a href="./PP_ApplicationEnglish.pdf" download="/PP_ApplicationEnglish.pdf">{t("application.applicationEng")}</a><br />
                            <a href="./PP_ApplicationSpanish.pdf" download="PP_ApplicationSpanish.pdf">{t("application.applicationEsp")}</a><br /><br /><br />
                            <h3>{t("application.subtitle")}</h3>
                            <ul>
                                <li>{t("application.listing1")}</li>
                                <li>{t("application.listing2")}</li>
                                <li>{t("application.listing3")}</li>
                                <li>{t("application.listing4")}</li>
                                <li>{t("application.listing5")}</li>
                                <li>{t("application.listing6")}</li>
                                <li>{t("application.listing7")} <a href="./CreditReportGuide.pdf" download="CreditReportGuide.pdf"> {t("application.listing7Info")}</a></li>
                            </ul>
                            <FileUpload t={t} />
                        </Card.Body>
                    </Card>
                </div>
            </div>

        )
    }
    return (null)

}

export default ApplicatonWrapper;

