import React, { useState, useEffect } from 'react'
import "../CSS/createAcc.css"
import Header from "./Header.js"
import api from '../api'
import Auth from "../auth.js"
import { Formik, Field } from 'formik';
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Form, Card, Col, InputGroup, Modal, Alert } from 'react-bootstrap';
import { getTranslations } from '../getTranslations'

const CreateAccountWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info
  const [userFields, setUserFields] = useState([null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
        api.getUserBySession().then(res => {
          let tempFields = [...userFields]
          tempFields[0] = true;
          tempFields[1] = res.data.user.admin
          tempFields[2] = res.data.user.applicationSent
          tempFields[3] = res.data.user.qualified
          tempFields[4] = res.data.user.username
          tempFields[5] = res.data.user.loan_information
          setUserFields(tempFields)
  
    
        }).catch((error) => {
          let tempFields = [...userFields]
          tempFields[0] = false;
          setUserFields(tempFields)
        })
      }, [])

  return(
    <div>
    {
        gotTrans && userFields[0] !== null ?
      <CreateAccount userFields={userFields}/>
      :
      null
    }
    </div>
  )

}





var yup = require('yup');

const CreateAccModal = (props) => {
    const history = useHistory();

    return (
        <div>
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>{props.t("createAcc.modal.title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{props.t("createAcc.modal.info")}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => { history.push('/login') }}>
                        {props.t("createAcc.modal.login")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>)
}

const CreateAccount = (props) => {
    const { t, i18n } = useTranslation();

    const setLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }

    const [showSuccess, setSuccess] = useState(false);
    const [errorCode, setErrorCode] = useState(0)


    const handleSubmit = async (data, { setSubmitting }) => {
        data.username = data.email;
        setSubmitting(true);

        await api.insertUser(data)
            .then(res => {
                setSubmitting(false);
                if (res.data.success)
                    setSuccess(true)
                else
                    alert("error occurred")

            }).catch((error) => {
                if (error.response.data) {
                    setSubmitting(false)
                    if (error.response.data.code === 1)
                        setErrorCode(1)
                    else
                        setErrorCode(2)
                }
            })
    }

    let errorAlert = (
        errorCode === 1 ?
            <Alert variant="danger">
                <Alert.Heading>{t('createAcc.response.userExists')}</Alert.Heading>
                <p>{t('createAcc.response.userExistsInfo')}</p>
            </Alert>
            :
            <Alert variant="danger">
                <Alert.Heading>{t('createAcc.response.unkown')}</Alert.Heading>
                <p>{t('createAcc.response.unkownInfo')}</p>
            </Alert>
    )

    const validName = (string) => /[.*+?^${}()|[\]\\<>~#%&:\/\s]/.test(string);
    const schema = yup.object({
        firstName: yup
            .string()
            .required(t("createAcc.firstName.required_E"))
            .test(t("createAcc.firstName.matches_E"), t("createAcc.firstName.matches_E"), (value) => !validName(value))
            .max(64, t("createAcc.firstName.max_E")),

        lastName: yup
            .string()
            .required(t("createAcc.lastName.required_E"))
            .test(t("createAcc.lastName.matches_E"), t("createAcc.lastName.matches_E"), (value) => !validName(value))
            .max(64, t("createAcc.lastName.max_E")),

        email: yup
            .string()
            .required(t("createAcc.email.required_E"))
            .matches(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                t("createAcc.email.matches_E"))
            .max(64, t("createAcc.email.max_E")),

        phoneNumber: yup
            .string()
            .required(t("createAcc.phone.required_E"))
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, t("createAcc.phone.matches_E"))
            .max(64, t("createAcc.phone.max_E")),


        password: yup
            .string()
            .required(t("createAcc.passwordFirst.required_E"))
            .matches(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/, t("createAcc.passwordFirst.matches_E"))
            .max(64, t("createAcc.passwordFirst.max_E")),

        passwordSecond: yup
            .string()
            .required(t("createAcc.passwordSecond.required_E"))
            .matches(/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/, t("createAcc.passwordSecond.matches_E"))
            .oneOf([yup.ref('password'), null], t("createAcc.passwordSecond.oneOf_E"))
            .max(64, t("createAcc.passwordSecond.max_E")),

        terms: yup
            .boolean()
            .oneOf([true],t("createAcc.terms.required_E")),
    });

    let cookieobj = Auth.parseCookie()
    if (cookieobj.qualified == "true") {
        return (
            <>
                {showSuccess === true ? <CreateAccModal t={t} /> : null}
                <Header setLanguage={setLanguage} username={props.userFields[4]} />
                <div className="row justify-content-center">
                    <Card as={Col} md="6">
                        <Card.Body>
                            {errorCode != 0 ? errorAlert : null}
                            <Formik
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    phoneNumber: '',
                                    email: '',
                                    password: '',
                                    username: '',
                                    terms: false,
                                    admin:false
                                }}
                            >


                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isSubmitting,
                                    errors,
                                }, ) => (
                                        <Form onSubmit={handleSubmit}>
                                            <h1 className="createAccountTitle">{t("createAcc.title")}</h1>
                                            <br />
                                            <Form.Row>
                                                <Form.Group as={Col} md="6">
                                                    <Form.Label>{t("createAcc.firstName.value")}</Form.Label>
                                                    <Field name="firstName" type="input" id="name" placeholder={t('createAcc.firstName.placeholder')}
                                                        isInvalid={!!errors.firstName && touched.firstName} isValid={!!!errors.firstName && touched.firstName} as={Form.Control} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstName}
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <Form.Label>{t("createAcc.lastName.value")}</Form.Label>
                                                    <Field name="lastName" type="input" placeholder={t('createAcc.lastName.placeholder')}
                                                        isInvalid={!!errors.lastName && touched.lastName} isValid={!!!errors.lastName && touched.lastName} as={Form.Control} />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastName}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>


                                            <Form.Row>
                                                <Form.Group as={Col} md="12" >
                                                    <Form.Label>{t("createAcc.phone.value")}</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-phone"></i></InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Field name="phoneNumber" type="Phone" placeholder={t('createAcc.phone.placeholder')}
                                                            isInvalid={!!errors.phoneNumber && touched.phoneNumber} isValid={!!!errors.phoneNumber && touched.phoneNumber} as={Form.Control} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.phoneNumber}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label>{t("createAcc.email.value")}</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-envelope"></i></InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Field name="email" type="input" placeholder={t('createAcc.email.placeholder')}
                                                            isInvalid={!!errors.email && touched.email} isValid={!!!errors.email && touched.email} as={Form.Control} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                    <Form.Text className="text-muted">
                                                        {t('createAcc.email.subtitle')}
                                                    </Form.Text>
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Row>
                                                <Form.Group as={Col} md="6">
                                                    <Form.Label>{t("createAcc.passwordFirst.value")}</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-key prefix"></i></InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Field name="password" type="Password" placeholder={t('createAcc.passwordFirst.placeholder')}
                                                            isInvalid={!!errors.password && touched.password} isValid={!!!errors.password && touched.password} as={Form.Control} />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                    <Form.Label>{t("createAcc.passwordSecond.value")}</Form.Label>
                                                    <InputGroup>
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text id="inputGroupPrepend"><i className="fa fa-key prefix"></i></InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <Form.Control name="passwordSecond"
                                                            type="Password"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={!!errors.passwordSecond && touched.passwordSecond}
                                                            isValid={!!!errors.passwordSecond && touched.passwordSecond} 
                                                            placeholder={t('createAcc.passwordSecond.placeholder')} />

                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.passwordSecond}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>

                                                    <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>

                                            {(props.userFields[0] === true && props.userFields[1] === true) &&
                                                <Form.Group>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label={t("createAcc.admin.value")}
                                                        name="admin"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </Form.Group>
                                            }


                                            <Form.Group>
                                                <Form.Check
                                                    required
                                                    type="checkbox"
                                                    label={
                                                        <a href="./Terms_and_Conditions.pdf" download="/Terms_and_Conditions.pdf">{t("createAcc.terms.value")}</a>
                                                    }
                                                    name="terms"
                                                    feedback={errors.terms}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid={!!errors.terms && touched.terms}
                                                />

                                            </Form.Group>
                                            <Button disabled={isSubmitting} type="submit">{t('createAcc.create')}</Button>
                                        </Form>
                                    )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </div>

            </>)
    }

    else {
        return (
            <Redirect
                to={{
                    pathname: "/qualifications",
                }}
            />)
    }
}

export default CreateAccountWrapper

