import React from 'react';
import "../CSS/Header.css"
import api from '../api'
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Navbar, Nav, Button } from 'react-bootstrap';


const Header = (props) => {
  const history = useHistory();
  const loca = useLocation();
  const { t, i18n } = useTranslation();

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang)
    props.setLanguage(lang)
  }


  const handleLogout =  () => {
    api.logout().then(() => {
      history.push("/login")
    }).catch((err)=>{
      console.log(err.response)
    })
  }

  function changeLangCookie(lang) {
    props.setLanguage(lang)
    document.cookie = "lang=" + lang
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" role="navigation" style={{backgroundColor: "#c6dabb"}}>
        <div className="container">
          <Navbar.Brand href="/" className="navbar-brand">
            <img
              className="headerBrandLogo"
              alt=""
              src={require("../pages/PPlogo2.png")}
            />{' '}
          </Navbar.Brand>
          <button className="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#exCollapsingNavbar">
            &#9776;
        </button>
          <div className="collapse navbar-collapse" id="exCollapsingNavbar">
            <ul className="nav navbar-nav" style={{ marginTop: "1%" }}>
              <li className="nav-item navitem-spacing"><a href="/" className="nav-link navbar-listitem-text">{t("header.about")}</a></li>
              <li className="nav-item navitem-spacing"><a href="/qualifications" className="nav-link navbar-listitem-text">{t("header.begin")}</a></li>
              <li className="nav-item navitem-spacing"><a target="_blank" href="http://www.projectprosper.org/en/contact-2/" className="nav-link navbar-listitem-text">{t("header.contact")}</a></li>
              {props.username != null ?
                <li className="nav-item navitem-spacing"><a href="/home" className="nav-link navbar-listitem-text">{t("header.userHome")}</a></li>
                :
                null}
            </ul>
            <ul className="nav navbar-nav flex-row justify-content-between ml-auto">
              <li style={{ marginTop: "4%" }}><i className="fa fa-globe"></i></li>
              <li style={{ marginTop: "2%" }}><Nav.Link onClick={() => { setLanguage("Eng"); changeLangCookie("Eng") }} className="englishTranslate">English</Nav.Link></li>
              <li style={{ marginTop: "2%" }}><Nav.Link onClick={() => { setLanguage("Esp"); changeLangCookie("Esp") }} className="spanishTranslate">Español</Nav.Link></li>
              <li className="nav-item order-2 order-md-1"><a href="#" className="nav-link" title="settings"><i className="fa fa-cog fa-fw fa-lg"></i></a></li>
              {props.username == null ?
                <button type="button" id="dropdownMenu1" className="btn btn-outline-secondary" style={{ width: "115px", whiteSpace: "nowrap" }}
                  onClick={() => {
                    if (loca.pathname === "/login")
                      window.location.reload();
                    else
                      history.push('/login')
                  }}
                >{t("header.login")} <span className="caret"></span></button>
                :
                <div className="pull-right">
                  <ul className="nav pull-right">
                    <li style={{ marginTop: "5%" }} className="dropdown"><a href="#" className="dropdown-toggle" data-toggle="dropdown">{props.username}<b className="caret"></b></a>
                      <ul className="dropdown-menu">
                        <div style={{ textAlign: "center" }}>
                          <li><a href="/home"><i className="icon-cog"></i>{t('header.options1')}</a></li><br />
                          <li className="divider"></li>
                          <li><Button size="sm" style={{width:"75%"}} variant="outline-secondary" onClick={handleLogout}>{t('header.options2')}</Button></li>
                        </div>
                      </ul>
                    </li>
                  </ul>
                </div>
              }

            </ul>
          </div>
        </div>
      </nav>


      <br /><br /><br /><br />
    </>)






}

export default Header;