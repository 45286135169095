import React, { useState, useEffect  } from 'react';
import "../CSS/Qualifications.css"
import Header from "./Header.js"
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Auth from "../auth.js"
import { Button, Form, Card,  Row, Col, Table, ListGroup, Modal  } from 'react-bootstrap';
import { getTranslations } from '../getTranslations'
import api from '../api'

const QualificationsWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info
  const [userFields, setUserFields] = useState([null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
      api.getUserBySession().then(res => {
        let tempFields = [...userFields]
        tempFields[0] = true;
        tempFields[1] = res.data.user.admin
        tempFields[2] = res.data.user.applicationSent
        tempFields[3] = res.data.user.qualified
        tempFields[4] = res.data.user.username
        tempFields[5] = res.data.user.loan_information
        setUserFields(tempFields)

  
      }).catch((error) => {
        let tempFields = [...userFields]
        tempFields[0] = false;
        setUserFields(tempFields)
      })
    }, [])

return(
  <div>
  {
    gotTrans && userFields[0] !== null ?
    <Qualifications userFields={userFields}/>
    :
    null
  }
  </div>
)

}


const Qualifications = (props) => {
 const { t, i18n } = useTranslation();

 const history = useHistory();

 const [Q, setQ] = useState([null, null, null, null, null, null]);
 const [qualified, setQualified] = useState(false);

 const setLanguage = (lang) => {
  i18n.changeLanguage(lang)
}

 const [showmodalFail, setShowFail] = useState(false);
 const [showmodalPass, setShowPass] = useState(false)
  const handleSubmit = (event) => {
    event.preventDefault();
      let qualified = isQualified()
      setQualified(qualified)

      setShowPass(qualified)
      setShowFail(!qualified)

  };

  //checks if user answers yes to all questions (which qualifies them)
  const [unanswered, setUnanswered] = useState(true)
  function checkUnanswered(){
    let index = 0;
    let unans = false;
    while(index < Q.length)
    {
        if(Q[index] == null){
          unans = true;
          break;
        }
        index++;
    }

    setUnanswered(unans)
  }

  function isQualified()
  {
    let qualifies = true;
    let index = 0;

    while(index < Q.length)
    {
        if(Q[index] == 0)
          qualifies = false;
        
        index++;
    }
      return qualifies;
  }
  
  useEffect(() => {
      checkUnanswered();
  });



  const handleClose = () => {
    setShowFail(false);
    setShowPass(false);
  }

  const routeAccountCreation = () =>
  {
    if(qualified)
    {
      document.cookie = "qualified=true"
      history.push("/CreateAccount")
    }
  }

  let cookieobj = Auth.parseCookie()
  if (cookieobj.qualified == "true") {
    return (
      <Redirect
        to={{
          pathname: "/CreateAccount",
        }}
      />)
    }
  
else{
  return (
    <div >
      <Header setLanguage={setLanguage} username={props.userFields[4]}/>
      {qualified == true ?
        <>
        <Modal show={showmodalPass} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("qualification.modalSuccessTitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t("qualification.modalSuccessInfo")}</Modal.Body>
          <Modal.Footer>

            <Button variant="primary" onClick={routeAccountCreation}>
              {t("qualification.modalCreateAccount")}
            </Button>

          </Modal.Footer>
        </Modal>
        </>//
        :
        <>
        <Modal show={showmodalFail} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("qualification.modalFailTitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {t("qualification.modalFailInfo")}
          <br /><p><strong>email:</strong> info@projectprosper.org<br /><strong>Phone:</strong> 727.538.4179</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{history.push('/')}}>
              {t("qualification.modalFailExit")}
            </Button>
            <Button variant="primary" onClick={handleClose}>
              {t("qualification.modalFailEdit")}
            </Button>
          </Modal.Footer>
        </Modal>
        </>
          
        }
        <div className="row justify-content-center" >
          <Card as={Col} md="6">
            <Card.Body >
          
              <Form onSubmit={handleSubmit}>
                <h1 className="qualifyTitle">{t("qualification.title")}</h1>
                <br/>
                <p className="qualifcationInfo">{t("qualification.info")}</p>
                  <br/><br/>

                  <Button variant="outline-primary" className="innerLanguageSwitch" onClick={() => {setLanguage("Eng"); document.cookie = "lang=Eng"}}>English</Button>{' '}
                  <Button variant="outline-danger" className="innerLanguageSwitch" onClick={() => {setLanguage("Esp"); document.cookie = "lang=Esp" }}>Español</Button>{' '}

                  <ListGroup.Item>
                  <Row>
                    <Col md={8} xs={10}></Col>
                    <Col md={{ span: 2, offset: 2 }} xs={2}>
                      <div style={{display:"flex",marginLeft:"0px",marginRight:"0px"}}>
                        <p style={{width:"30px", marginRight:"3px"}}>{t("qualification.yesTag")}</p>
                        <p style={{width:"10px"}}></p>
                        <p >{t("qualification.noTag")}</p>
                      </div>
                    </Col>
                  </Row>
                  
                      <Row>
                        <Col md={8} xs={10}>
                          <p>{t("qualification.question1")}</p>
                        </Col>
                        <Col md={{ span: 2, offset: 2 }} xs={2} style={{display:"flex"}}>
                          <Form.Check onChange={() => {let qarray=[...Q]; qarray[0]=1; setQ(qarray)}} checked={Q[0] === 1}  style={{width:"40px"}} custom type="radio" label="" id="qualRadioYes0"/>
                          <Form.Check onChange={() => {let qarray=[...Q]; qarray[0]=0; setQ(qarray)}} checked={Q[0] === 0}  custom type="radio" label=""  id="qualRadioNo0"/>
                        </Col>

                      </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                  <Row >
                    <Col md={8} xs={10}>
                      <p>{t("qualification.question2")}</p>
                    </Col>
                    <Col md={{ span: 2, offset: 2 }} xs={2} style={{display:"flex"}}>
                      <Form.Check onChange={() => {let qarray=[...Q]; qarray[1]=1; setQ(qarray)}} checked={Q[1] === 1}  style={{width:"40px"}} custom type="radio" label="" name="set1" id="qualRadioYes1"/>
                      <Form.Check onChange={() => {let qarray=[...Q]; qarray[1]=0; setQ(qarray)}} checked={Q[1] === 0}  custom type="radio" label="" name="set1" id="qualRadioNo1"/>
                    </Col>
                  </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                  <Row>
                    <Col md={8} xs={10}>
                      <p>{t("qualification.question3")}</p>
                    </Col>
                    <Col md={{ span: 2, offset: 2 }} xs={2} style={{display:"flex"}}>
                      <Form.Check onChange={() => {let qarray=[...Q]; qarray[2]=1; setQ(qarray)}} checked={Q[2] === 1}  style={{width:"40px"}} custom type="radio" label="" name="set2" id="qualRadioYes2"/>
                      <Form.Check onChange={() => {let qarray=[...Q]; qarray[2]=0; setQ(qarray)}} checked={Q[2] === 0}   custom type="radio" label="" name="set2" id="qualRadioNo2"/>
                    </Col>
                  </Row>
                  </ListGroup.Item>

                  <ListGroup.Item >
                  <Row>
                    <Col md={8} xs={10}>
                      <p>{t("qualification.question4")}</p>
                    </Col>
                    <Col md={{ span: 2, offset: 2 }} xs={2} style={{display:"flex"}}>
                      <Form.Check onChange={() => {let qarray=[...Q]; qarray[3]=1; setQ(qarray)}} checked={Q[3] === 1} style={{width:"40px"}} custom type="radio" label="" name="set3" id="qualRadioYes3"/>
                      <Form.Check onChange={() => {let qarray=[...Q]; qarray[3]=0; setQ(qarray)}} checked={Q[3] === 0} custom type="radio" label="" name="set3" id="qualRadioNo3"/>
                    </Col>
                  </Row>
                  </ListGroup.Item>

                <ListGroup.Item>
                <Row>
                  <Col md={8} xs={10}>
                    <p>{t("qualification.question5")}</p>
                  </Col>
                  <Col md={{ span: 2, offset: 2 }} xs={2} style={{display:"flex"}}>
                    <Form.Check onChange={() => {let qarray=[...Q]; qarray[4]=1; setQ(qarray)}} checked={Q[4] === 1} style={{width:"40px"}} custom type="radio" label="" name="set4" id="qualRadioYes4"/>
                    <Form.Check onChange={() => {let qarray=[...Q]; qarray[4]=0; setQ(qarray)}} checked={Q[4] === 0} custom type="radio" label="" name="set4" id="qualRadioNo4"/>
                  </Col>
                </Row>
                  </ListGroup.Item>

                <ListGroup.Item>
                <Row>
                  <Col md={8} xs={10}>
                    <p>{t("qualification.question6")}</p>
                  </Col>
                  <Col md={{ span: 2, offset: 2 }} xs={2} style={{display:"flex"}}>
                    <Form.Check onChange={() => {let qarray=[...Q]; qarray[5]=1; setQ(qarray)}} checked={Q[5] === 1} style={{width:"40px"}} custom type="radio" label="" name="set5" id="qualRadioYes5"/>
                    <Form.Check onChange={() => {let qarray=[...Q]; qarray[5]=0; setQ(qarray)}} checked={Q[5] === 0} custom type="radio" label="" name="set5" id="qualRadioNo5"/>
                  </Col>
                </Row>
                </ListGroup.Item>   
                
                <div style={{overflowX:"auto"}}>
                <Table striped bordered hover className="incomeTable">
                  <thead align="center">
                      <tr>
                        <th colSpan="8" className="tableTitle">{t("qualification.incomeTableTitle")}</th>
                      </tr>
                  </thead>

                  <tbody>
                      <tr>
                          <td>1</td>
                          <td>2</td>
                          <td>3</td>
                          <td>4</td>
                          <td>5</td>
                          <td>6</td>
                          <td>7</td>
                          <td>8</td>
                      </tr>
                      <tr>
                          <td>{t("qualification.tableValue1")}</td>
                          <td>{t("qualification.tableValue2")}</td>
                          <td>{t("qualification.tableValue3")}</td>
                          <td>{t("qualification.tableValue4")}</td>
                          <td>{t("qualification.tableValue5")}</td>
                          <td>{t("qualification.tableValue6")}</td>
                          <td>{t("qualification.tableValue7")}</td>
                          <td>{t("qualification.tableValue8")}</td>
                      </tr>
                  </tbody>
                </Table>
                </div>

                <Button type="submit" className="submitButton" disabled={unanswered}>Submit</Button>
              </Form>

          </Card.Body>  
      </Card>
      </div>
    </div>
  );
 }
}

export default QualificationsWrapper;

