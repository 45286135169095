import React, { useState, useEffect } from 'react';
import Header from "./Header.js"
import api from '../api'
import "../CSS/DefaultPage.css"
import { useHistory, } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Card, Col, Button } from 'react-bootstrap';
import { getTranslations } from '../getTranslations'

const DefaultPageWrapper = (props) => {
  //const [state, setState] = useState(getTranslations());
  const [gotTrans, setGotTrans] = useState(false);
  // mine: [0]=loggedIn, [1]=isAdmin, [2]=hasSentApp, [3]=qualified  [4]=username, [5]=loan Info
  const [userFields, setUserFields] = useState([null, null, null, null, null, null])

  useEffect(() => {
    getTranslations().then(res => {setGotTrans(res)}).catch((err)=>{console.log(err); setGotTrans(false)})
    api.getUserBySession().then(res => {
      let tempFields = [...userFields]
      tempFields[0] = true;
      tempFields[1] = res.data.user.admin
      tempFields[2] = res.data.user.applicationSent
      tempFields[3] = res.data.user.qualified
      tempFields[4] = res.data.user.username
      tempFields[5] = res.data.user.loan_information
      setUserFields(tempFields)


    }).catch((error) => {
      let tempFields = [...userFields]
      tempFields[0] = false;
      setUserFields(tempFields)
    })
  }, [])

  return (
    <div>
      {
        gotTrans === true && userFields[0] !== null ?
          <DefaultPage userFields={userFields} />
          :
          null
      }
    </div>
  )

}


const DefaultPage = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const setLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }


  return (
    <div >
      <Header setLanguage={setLanguage} username={props.userFields[4]} />
      <div className="row justify-content-center">
        <Card as={Col} md="6">
          <Card.Body>
            <h1 align="center">{t("landing.title")}</h1>
            <br /><br />
            <p>{t("landing.body")}</p>

            <div style={{ textAlign: "center" }}>
              <Button style={{ width: "25%" }} variant="outline-success" onClick={() => { history.push("/qualifications") }}>{t("landing.btn")}</Button>
            </div>

          </Card.Body>
        </Card>
      </div>
    </div>
  );

}

export default DefaultPageWrapper;
