class Auth {
    constructor() {
      this.authenticated = false;
    }
  
    isAuthenticated() {
      return this.authenticated;
    }

    parseCookie() {
        return document.cookie.split('; ').reduce((prev, current) => {
            const [name, value] = current.split('=');
            prev[name] = value;
            return prev
          }, {});
    }

    deleteAllCookies(){
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    deleteCookie( name ) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }

      updateCookie(cookie){
          document.cookie = cookie + '=false'
      }
  }
  
  export default new Auth();
  