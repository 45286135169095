import axios from 'axios'


const api = axios.create({
    baseURL: process.env.REACT_APP_POST_URL + '/api'
})
axios.defaults.withCredentials = true

const headers = {
    'Content-Type': 'multipart/form-data'
  }

export const insertUser = payload => api.post('/createUser', payload)
export const getUsers = () => api.get('/getUsers', {withCredentials: true })
export const Login = payload => api.post('/login',{data: payload}, {withCredentials: true })
export const getUserBySession = () =>api.get('/session', {withCredentials: true })
export const logout = ()=>api.get('/logout', {withCredentials: true })
export const createToken = (payload)=>api.post('/createToken', payload)
export const checkToken = (payload)=>api.post('/checkToken', payload)
export const resetPassword = (payload)=>api.post('/resetPassword', payload)
export const uploadFiles = (payload) => api.post('/uploadFile', payload, {withCredentials: true})
export const uploadReport = (payload) => api.post('/uploadReport', payload,  {headers: headers, withCredentials: true})
export const setQualified = (payload) => api.post('/setQualified', payload,  {withCredentials: true})
export const downloadOverallReport = (payload) => api.post('/overallReport', payload,  {responseType: 'arraybuffer', withCredentials: true})
export const downloadIndivdReport = (payload) => api.post('/individReport', payload,  {responseType: 'arraybuffer', withCredentials: true})
export const addLoan = (payload) => api.post('/addLoan', payload,  {withCredentials: true})
export const adminDelete = (payload) => api.post('/adminDelete', payload,  {withCredentials: true})
export const deleteLoan = (payload) => api.post('/deleteLoan', payload,  {withCredentials: true})
export const getApplicationLink = (payload) => api.post('/getApplicationLink', payload,  {withCredentials: true})
export const clearUserLoan = (payload) => api.post('/clearUserLoan', payload,  {withCredentials: true})
export const getTranslation = () => api.get('/getTranslation')


export const uploadEngTranslation = (payload) => api.post('/uploadEngTranslation', payload,  {headers: headers, withCredentials: true})
export const uploadEspTranslation = (payload) => api.post('/uploadEspTranslation', payload,  {headers: headers, withCredentials: true})


const apis = {
    logout,
    setQualified,
    clearUserLoan,
    uploadEngTranslation,
    getApplicationLink,
    uploadEspTranslation,
    deleteLoan,
    getTranslation,
    addLoan,
    adminDelete,
    downloadOverallReport,
    downloadIndivdReport,
    uploadReport,
    uploadFiles,
    createToken,
    checkToken,
    resetPassword,
    insertUser,
    getUsers,
    Login,
    getUserBySession
}

export default apis